import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import styles from "./index.module.scss";

export function CookieBanner() {
  const [cookies, setCookie] = useCookies(["consentValue"]);
  const [pageViewSent, setPageViewSent] = useState(false);

  let handleAction = useCallback(
    (action: "accepted" | "refused") => {
      setCookie("consentValue", action);

      switch (action) {
        case "accepted":
          if (window?.gtag) {
            setPageViewSent(true);

            window.gtag("consent", "update", {
              analytics_storage: "granted",
            });
            window.gtag("js", new Date());
          }
          break;
        case "refused":
          if (window?.gtag) {

            window.gtag("consent", "update", {
              analytics_storage: "denied",
            });
          }
          break;
      }
    },
    [setCookie]
  );

  useEffect(() => {
    let mounted = true;
    if (!pageViewSent && cookies.consentValue === "accepted" && mounted) {
      setPageViewSent(true);
      if (window?.gtag) {
        window.gtag("consent", "update", {
          analytics_storage: "granted",
        });
        window.gtag("js", new Date());

        // window.gtag("event", "page_view", {
        //   page_title: "<Page Title>",
        //   page_location: "<Page Location>",
        //   page_path: "<Page Path>",
        //   send_to: "G-7G1JR0XKX8",
        // });
      }
    }

    return () => {
      mounted = false;
    };
  }, [pageViewSent, cookies.consentValue]);

  if (
    cookies.consentValue === "accepted" ||
    cookies.consentValue === "refused"
  ) {
    return null;
  }

  return (
    <div className={styles.cookieBanner}>
      <div className={styles.bannerContent}>
        <h2> Les cookies, ça roule</h2>
        <p>
          Notre site utilise des cookies pour mesurer l'audience et la
          performance du site.
        </p>
      </div>
      <div className={styles.buttonGroup}>
        <button
          className={styles.buttonAccept}
          onClick={() => {
            handleAction("accepted");
          }}
        >
          accepter les cookies
        </button>
        <button
          className={styles.buttonRefuse}
          onClick={() => {
            handleAction("refused");
          }}
        >
          refuser les cookies
        </button>
      </div>
    </div>
  );
}
