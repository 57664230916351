import styles from "./App.module.scss";
import { ReactComponent as ReactLogo } from "./clean_different_animated.svg";
import { CookieBanner } from "./components/CookieBanner";

import {
  Variants,
  motion,
  useAnimationControls,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { CardGrid } from "./components/CardGrid";

const MODES = {
  "0": {
    backGround: "black",
    fontColor: "white",
  },
  "1": {
    backGround: "#aaa9ad",
    fontColor: "white",
  },
  "2": {
    backGround: "#aaa9ad",
    fontColor: "black",
  },
};

function App(props: any) {
  const { scrollYProgress } = useScroll();
  const control = useAnimationControls();
  const params = new URLSearchParams(window.location.search);

  const styleMode = params.get("mode") as ("0" | "1" | "2" )

  const [animationLaunched, setAnimationLaunched] = useState(false);
  const [mode, setMode] = useState(MODES[styleMode] || MODES["0"]);
  const TRESHOLDS = {
    low: isMobile ? 0.1 : 0.3,
    high: 0.6,
  };

  console.log(styleMode)

  const variant1: Variants = {
    waves: {
      translateX: ["1", "-25%", "-50%", "-25%", "1"],
      transition: {
        duration: 20,
        repeat: Infinity,
        repeatDelay: 2,
        repeatType: "reverse",
        ease: "linear",
      },
    },
  };


  // useEffect(() => {
  //   const truc = setTimeout(() => {
  //   //   window.scrollTo({
  //   //     left: 0,
  //   //     top: window.innerHeight,
  //   //     behavior: "smooth",
  //   //   });
  //   // }, 4000);
  //   // if (scrollYProgress.get() > 0) {
  //   //   clearTimeout(truc);
  //   // }
  // });

  useEffect(() => {
    control.set({ x: "-100%" });

    if (scrollYProgress.get() > TRESHOLDS.low && !animationLaunched) {
      setAnimationLaunched(true);
      control.start({
        x: 0,
        transition: {
          duration: 1,
        },
      });
    }
  }, [
    TRESHOLDS.low,
    control,
    setAnimationLaunched,
    scrollYProgress,
    animationLaunched,
  ]);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (
      TRESHOLDS.low < Number(latest) &&
      Number(latest) < TRESHOLDS.high &&
      !animationLaunched
    ) {
      setAnimationLaunched(true);
      control.start({
        x: 0,
        transition: {
          duration: 1,
        },
      });
    }
  });

  return (
    <motion.div
      className={styles.App}
      style={{
        backgroundColor: mode.backGround,
      }}
    >
      <div>
        <button
          onClick={() => {
            setMode({
              backGround: "black",
              fontColor: "white",
            });
          }}
        >
          {" "}
          mode 1
        </button>
        <button
          onClick={() => {
            setMode({
              backGround: "#aaa9ad",
              fontColor: "white",
            });
          }}
        >
          {" "}
          mode 2
        </button>
        <button
          onClick={() => {
            setMode({
              backGround: "#aaa9ad",
              fontColor: "black",
            });
          }}
        >
          {" "}
          mode 3
        </button>
      </div>
      <CookieBanner />
      <motion.div
        style={{ scaleX: scrollYProgress }}
        className={styles.scrollProgressBar}
      />
      <div
        className={styles.firstSection}
        style={{
          background:
            mode.backGround === "black"
              ? "radial-gradient(circle, rgba(170,169,173,0.1) 0%, rgba(0,0,0,1) 35%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%)"
              : "radial-gradient(circle, rgba(238,238,238,0.1) 0%, rgba(170,169,173,1) 35%)",
        }}
      >
        <div className={styles.title}>
          <motion.h1
            style={{
              color: mode.fontColor,
              borderBottom:
                mode.backGround === "black"
                  ? "3px solid #aaa9ad"
                  : "3px solid black",
            }}
            initial={{
              width: "0%",
            }}
            animate={{
              width: "100%",
              transition: {
                duration: 3,
                ease: "easeOut",
              },
            }}
          >
           Logo
          </motion.h1>
        </div>
        <div className={styles.cleanDifferent}>
          <ReactLogo className={styles.logo}/>
        </div>
      </div>

      <motion.div
        className={styles.secondSection}
        id="toto"
        animate={control}
        style={{ x: "-100%" }}
      >
        <div className={`${styles.content} `}>
          <h2>
            Vous en avez{" "}
            <span className={styles.titleContentLastWord}>assez</span>
          </h2>

          <CardGrid />

          <div className={styles.explicationBox}>
            <p>
              Bref, vous désirez pouvoir enfin profiter d'un service de
              nettoyage en toute quiétude ?
            </p>

            <p>
              C'est sur la base de ce triste constat qui n'a que trop duré que
              Clean Different se propose de redonner au nettoyage ses lettres de
              noblesse et dont les valeurs se résument en trois mots :
              confiance, respect, excellence.
              <br />
              Pour mener cet ambitieux projet à bien et ainsi de vous faire
              découvrir un service à la fois plus simple et interactif, focalisé
              sur la performance et la qualité, nous avons besoin de vous !
            </p>

            <p>
              Devenez-en dès maintenant partie prenante en consacrant quelques
              minutes de votre temps à notre questionnaire !
            </p>
            <h4>Nous avons besoin de votre avis !</h4>
            <motion.a
              className={styles.box}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfUvZBk2Y5t13vbLGZdWRvL12aGG5bWKlopLiQtVnay_uceWQ/viewform"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => {}}
            >
              {" "}
              <p>Cliquez ici</p>
            </motion.a>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default App;
