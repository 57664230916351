import { useState } from "react";
import { Card } from "../Card";
import {
  faEuro,
  faMasksTheater,
  faPeopleGroup,
  faThumbsDown,
  faCalendarXmark,
  faHourglassHalf,
} from "@fortawesome/free-solid-svg-icons";
import { LayoutGroup, Variants } from "framer-motion";

import styles from "./index.module.scss";

export function CardGrid() {
  const [hovered, setHovered] = useState<string | null>(null);

  const variant1: Variants = {
    logo: {
      rotate: 360,
      transition: {
        type: "tween",
        duration: 1,
        repeat: Infinity,
        repeatDelay: 2,
        repeatType: "reverse",
      },
    },
  };

  const variant2: Variants = {
    logo: {
      y: 10,
      transition: {
        type: "spring",
        stiffness: 1000,
        damping: 10,
        repeat: Infinity,
        repeatType: "reverse",
        repeatDelay: 1,
      },
    },
  };

  return (
    <div className={styles.gridContainer}>
      <LayoutGroup>
        <Card
          id="1"
          hovered={hovered}
          setHovered={setHovered}
          icon={faCalendarXmark}
          textContent={
            <p>
              D'un prestataire de nettoyage qui tarde à venir ou qui n'a pas
              honoré son rendez-vous ?
            </p>
          }
        />

        <Card
          id="2"
          hovered={hovered}
          setHovered={setHovered}
          icon={faThumbsDown}
          variants={variant2}
          textContent={
            <p>
              D'avoir à repasser derrière l'intervenant et de payer au
              final comme si le travail commandé était bien conforme à vos
              attentes ?
            </p>
          }
        />
        <Card
          id="3"
          hovered={hovered}
          setHovered={setHovered}
          icon={faEuro}
          textContent={
            <p>
              D'être mis(e) devant le fait accompli et de devoir fournir vos
              propres produits ménagers, quand ce n'est pas votre propre
              aspirateur, ce qui ne vient pas pour autant en déduction du prix à
              payer ?
            </p>
          }
        />
        <Card
          id="4"
          hovered={hovered}
          setHovered={setHovered}
          icon={faMasksTheater}
          textContent={
            <p>
              De constater que certains de vos biens disparaissent après
              le passage de l'intervenant ?
            </p>
          }
        />
        <Card
          id="5"
          hovered={hovered}
          setHovered={setHovered}
          icon={faHourglassHalf}
          variants={variant1}
          textContent={
            <p>De gérer vos rendez-vous rapidement et simplement ?</p>
          }
        />
        <Card
          id="6"
          hovered={hovered}
          setHovered={setHovered}
          icon={faPeopleGroup}
          textContent={
            <p>
              De ne pas obtenir une oreille suffisamment attentive à une
              réclamation et la réactivité censée y répondre ?
            </p>
          }
        />
      </LayoutGroup>
    </div>
  );
}
