import { useEffect } from "react";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useAnimationControls, Variants } from "framer-motion";
import styles from "./index.module.scss";

interface CardProps {
  icon: IconDefinition;
  textContent: JSX.Element;
  variants?: Variants;
  hovered: null | string;
  setHovered: (id: string | null) => void;
  id: string;
}

export function Card(props: CardProps) {
  const { icon, textContent, variants, id, hovered, setHovered } = props;
  const control = useAnimationControls();
  const controlIcon = useAnimationControls();

  const cardVariant: Variants = {
    cardHovered: {
      scale: 1.1,
      transition: {
        duration: 0.2,
      },
    },
    reset: {
      scale: 1.0,
      transition: {
        duration: 0.2,
      },
    },
    otherHovered: {
      scale: 0.9,
      transition: {
        duration: 0.2,
      },
    },
  };

  useEffect(() => {
    if(hovered === null) {
      control.start('reset');
    }
    if(hovered === id){
      control.start('cardHovered');
      controlIcon.start('logo');
    }
    if(hovered !== id && hovered !== null) {
      control.start('otherHovered');
    }

  }, [hovered, id, control, controlIcon])

  return (
    <motion.div
      className={styles.card}
      variants={cardVariant}
      whileHover={["logo"]}
      layout
      animate={control}
      onHoverStart={() => setHovered(id)}
      onHoverEnd={() => setHovered(null)}
    >
      <motion.div variants={variants} className={styles.iconHeader}>
        <FontAwesomeIcon icon={icon} />
      </motion.div>
      <div className={styles.content}>{textContent}</div>
    </motion.div>
  );
}
